import {useQuery} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphViewKind} from '../../../../../../components/graph/interface';

function getQueryViewKinds(queryId: number) {
    return apiClient.get<GraphViewKind[]>({
        path: `queries-service/api/queries/${queryId}/connection-graph/views`
    });
}

export function useGetQueryViewKinds(queryId: number) {
    return useQuery(['get-query-view-types', queryId], {
        queryFn: () => getQueryViewKinds(queryId)
    });
}
