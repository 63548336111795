import {Link, Controls, ControlsItem} from '@genestack/ui';
import React from 'react';
import styles from './document-unit.module.css';
import {addGlobalVersionToUrl} from '../../../../../hooks/use-search-params';
import {DocumentTypeLabel} from '../../../../document-type-label';

interface Props {
    globalVersionId: number;
    nodeId: string;
    document: ViewModeDocInfo;
}

export const DocumentUnit = ({globalVersionId, nodeId, document}: Props) => {
    const basename = window.location.origin;
    const nodePath = addGlobalVersionToUrl(
        `/documents/explore/${document.accession}/@${nodeId}`,
        globalVersionId
    );

    return (
        <Controls>
            <ControlsItem className={styles.docType}>
                <DocumentTypeLabel type={document.type} readOnly={document.readOnly} />
            </ControlsItem>
            <ControlsItem>
                <Link href={`${basename}${nodePath}`} target="_blank">
                    {document.name}
                </Link>
            </ControlsItem>
        </Controls>
    );
};
