import {
    GraphResponse,
    QueryNodeType,
    StructuredGraphEdge,
    StructuredGraphNode
} from '../../interface';

/** The class that constructs the graph,
 *  and provides helper functions */
export class StructuredGraph {
    public readonly startNodes: StructuredGraphNode[];
    public readonly allNodes: StructuredGraphNode[];
    public readonly allEdges: StructuredGraphEdge[];
    public readonly weightFromStartMap: {[id: number]: number};
    public readonly weightFromEndMap: {[id: number]: number};
    private readonly nodesMap: {[id: number]: StructuredGraphNode};
    private readonly nodesByRawIdMap: {[id: number]: StructuredGraphNode};
    private readonly edgesMap: {[id: number]: StructuredGraphEdge};

    constructor(graphData: GraphResponse) {
        const {startNodes, allNodes, allEdges} = StructuredGraph.constructGraph(graphData);
        this.startNodes = startNodes;
        this.allEdges = allEdges;
        this.allNodes = allNodes;
        const nodesMap: {[id: number]: StructuredGraphNode} = {};
        allNodes.forEach((node) => {
            nodesMap[node.id] = node;
        });
        this.nodesMap = nodesMap;
        const nodesByRawIdMap: {[id: number]: StructuredGraphNode} = {};
        allNodes.forEach((node) => {
            node.rawNodeIds.forEach((rawId) => {
                nodesByRawIdMap[rawId] = node;
            });
        });
        this.nodesByRawIdMap = nodesByRawIdMap;
        const edgesMap: {[id: number]: StructuredGraphEdge} = {};
        allEdges.forEach((edge) => {
            edgesMap[edge.id] = edge;
        });
        this.edgesMap = edgesMap;
        this.weightFromStartMap = StructuredGraph.getNodesWeight(allNodes, QueryNodeType.START);
        this.weightFromEndMap = StructuredGraph.getNodesWeight(allNodes, QueryNodeType.END);
    }

    public getNode(nodeId: number) {
        return this.nodesMap[nodeId];
    }

    public getNodeByRawId(rawNodeId: number) {
        return this.nodesByRawIdMap[rawNodeId];
    }

    public getEdge(edgeId: number) {
        return this.edgesMap[edgeId];
    }

    public static getNodesWeight(
        nodes: StructuredGraphNode[],
        entryPoint: QueryNodeType.START | QueryNodeType.END
    ) {
        const nodeWeightMap = new Map<number, number>();
        const queue: StructuredGraphNode[] = [];

        // Initialize the weights and queue with start nodes
        for (const node of nodes) {
            if (node.nodeType === entryPoint) {
                nodeWeightMap.set(node.id, 0);
                queue.push(node);
            }
        }

        // Perform BFS to calculate the weights
        while (queue.length > 0) {
            const currentNode = queue.shift()!;
            const currentWeight = nodeWeightMap.get(currentNode.id)!;
            const nextEdges =
                entryPoint === QueryNodeType.START
                    ? currentNode.outgoingEdges
                    : currentNode.incomingEdges;

            for (const edge of nextEdges) {
                const nextNode =
                    entryPoint === QueryNodeType.START ? edge.targetNode : edge.sourceNode;
                const newWeight = currentWeight + 1;

                if (
                    !nodeWeightMap.has(nextNode.id) ||
                    newWeight < nodeWeightMap.get(nextNode.id)!
                ) {
                    nodeWeightMap.set(nextNode.id, newWeight);
                    queue.push(nextNode);
                }
            }
        }

        const mapToObject = (map: Map<number, number>) => Object.fromEntries(map.entries());
        return mapToObject(nodeWeightMap);
    }

    private static constructGraph = (graphData: GraphResponse) => {
        const nodes: StructuredGraphNode[] = graphData.nodes.map((node) => ({
            id: node.id,
            // we use biokb graph only in queries
            nodeType: node.nodeType as QueryNodeType,
            incomingEdges: [],
            outgoingEdges: [],
            rawNodeIds: node.rawNodeIds
        }));
        const edges: StructuredGraphEdge[] = graphData.edges.map((edge) => ({
            id: edge.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sourceNode: nodes.find((node) => node.id === edge.startViewNodeId)!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            targetNode: nodes.find((node) => node.id === edge.endViewNodeId)!,
            presentation: edge.presentation
        }));

        nodes.forEach((node) => {
            edges.forEach((edge) => {
                if (edge.targetNode.id === node.id) {
                    node.incomingEdges.push(edge);
                }

                if (edge.sourceNode.id === node.id) {
                    node.outgoingEdges.push(edge);
                }
            });
        });

        return {
            startNodes: nodes.filter((node) => node.nodeType === QueryNodeType.START),
            allNodes: nodes,
            allEdges: edges
        };
    };
}
