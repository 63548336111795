import React from 'react';
import {GraphViewKind} from '../../../../../../components/graph/interface';
import {NeighborhoodGraphDirection} from '../interface';

export function useNeighborhoodGraphSettings() {
    const [selectedViewKind, setSelectedViewKind] = React.useState(GraphViewKind.MERGED);
    const toggleSelectedViewKind = React.useCallback(() => {
        setSelectedViewKind((prevState) =>
            prevState === GraphViewKind.IDENTICAL ? GraphViewKind.MERGED : GraphViewKind.IDENTICAL
        );
    }, []);
    const [maxDistance, setMaxDistance] = React.useState(3);
    const [direction, setDirection] = React.useState(NeighborhoodGraphDirection.OUTGOING);

    return {
        selectedViewKind,
        toggleSelectedViewKind,
        maxDistance,
        setMaxDistance,
        direction,
        setDirection
    };
}
