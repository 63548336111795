import React from 'react';
import {Position} from 'cytoscape';
import {GraphEdge, GraphLayout, GraphNode} from '../../../../../../components/graph/interface';
import {useCalculateLayout} from '../../../../../../components/graph/cytoscape-graph/graph-logic/use-calculate-layout';
import {QueryGraphLayoutAlgorithm} from '../../../../../../components/graph/cytoscape-graph/interface';

interface Props {
    nodes: GraphNode[];
    edges: GraphEdge[];
}

export function useNeighborhoodGraphProps(props: Props) {
    const [isBrowserUnsupported, setIsBrowserUnsupported] = React.useState<boolean>();
    const [nodePositions, setNodePositions] = React.useState<string>('');
    const [zoom, setZoom] = React.useState<number>(0);
    const [pan, setPan] = React.useState<Position>({x: 0, y: 0});

    const calculateLayoutRef = React.useRef<ReturnType<typeof useCalculateLayout>>();
    calculateLayoutRef.current = useCalculateLayout({
        setIsBrowserUnsupported,
        nodes: props.nodes,
        edges: props.edges,
        layoutAlgorithm: QueryGraphLayoutAlgorithm.LAYERED_VERTICAL,
        setNodePositions,
        nodePositions
    });

    const nodesToString = props.nodes.toString();
    React.useEffect(() => {
        setNodePositions('');
        calculateLayoutRef.current!();
    }, [nodesToString]);

    return {
        pan,
        zoom,
        setPan,
        setZoom,
        setNodePositions,
        isBrowserUnsupported,
        nodePositions: nodePositions ? (JSON.parse(nodePositions) as GraphLayout) : undefined,
        handleNodeMoved: React.useCallback(
            (newLayout: GraphLayout) => setNodePositions(JSON.stringify(newLayout)),
            []
        )
    };
}
