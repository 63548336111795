import {getLineSeparator} from '../../../../utils/get-client-os';

const defaultMaxAllowedLength = 16;
const defaultMaxLinesNumber = 4;
export const lineSeparator = getLineSeparator();

export function formatNodeLabel(
    label: string,
    maxAllowedLength: number = defaultMaxAllowedLength,
    maxLinesNumber: number = defaultMaxLinesNumber
) {
    if (label.length < maxAllowedLength) {
        return label;
    }

    const labelWithSpacedBrackets = label.replace(/(\S)\(/g, '$1 (').replace(/\)(\S)/g, ') $1');
    const words = labelWithSpacedBrackets.split(' ');
    const lines = [''];
    const getCurrentLine = () => lines[lines.length - 1];
    const isLongLabel = label.length >= maxAllowedLength * maxLinesNumber;

    for (let i = 0; i < words.length; i++) {
        let processedWord = words[i];
        const getCurrentLineCharsLeft = () => maxAllowedLength - getCurrentLine().length;

        // handle long word
        while (
            (processedWord.length > maxAllowedLength ||
                (getCurrentLineCharsLeft() < processedWord.length &&
                    getCurrentLine().length < maxAllowedLength / 3 &&
                    isLongLabel)) &&
            lines.length < maxLinesNumber
        ) {
            const carryPosition = getCurrentLineCharsLeft();
            lines[lines.length - 1] = `${
                // eslint-disable-next-line prefer-template
                getCurrentLine() ? getCurrentLine() + ' ' : ''
            }${processedWord.substring(0, carryPosition)}-`;
            lines.push('');
            processedWord = processedWord.substring(carryPosition);
        }

        if (getCurrentLineCharsLeft() > processedWord.length) {
            lines[lines.length - 1] = !getCurrentLine().length
                ? processedWord
                : `${getCurrentLine()} ${processedWord}`;
            // eslint-disable-next-line no-continue
            continue;
        }

        if (lines.length === maxLinesNumber) {
            const availableSpaceLeft = getCurrentLineCharsLeft() - 2;
            lines[lines.length - 1] = `${getCurrentLine()} ${
                availableSpaceLeft > 0 ? processedWord.substring(0, availableSpaceLeft) : ''
            }...`;
            break;
        }

        lines.push(processedWord);
    }

    return lines.join(lineSeparator);
}

export function getEdgeCytoscapeId(edgeId: number) {
    return `edge-${edgeId}`;
}
