import React from 'react';

import {GenestackGraphView} from '../../../../components/graph/biokb-graph/genestack-graph-view';
import {useQueryGraphProps} from './results-graph/use-query-graph-props';
import {CytoscapeGraphView} from '../../../../components/graph/cytoscape-graph/graph-rendering/components/cytoscape-graph-view';
import {BrowserNotSupportedView} from '../../../../components/graph/common-components/browser-not-supported-view';
import {isGraphUnmanageable} from './results-graph/helpers';
import {GraphLoadingComponent} from '../../../../components/graph/common-components/graph-loading-component';
import {useResultsListProps} from './result-chains-panel/use-results-list-props';
import {GraphType} from '../../../../components/graph/interface';
import {useGraphSelectionProps} from '../../../../components/graph/use-graph-selection-props';
import {MaxPathLengthOptionResult} from './results-graph/requests/use-query-result-max-length-options';
import {GraphDisplayErr} from '../../../../components/graph/cytoscape-graph/graph-rendering/components/graph-display-err';
import {useIsCantDisplayGraph} from './results-graph/use-is-cant-display-graph';

interface Props {
    graphProps: ReturnType<typeof useQueryGraphProps>;
    resultsListProps: ReturnType<typeof useResultsListProps>;
    selectionProps: ReturnType<typeof useGraphSelectionProps>;
    maxPathLengthOptions: MaxPathLengthOptionResult;
    isCantDisplayGraphProps: ReturnType<typeof useIsCantDisplayGraph>;
}

export function QueryResults(props: Props) {
    // todo: refactor this file
    const {isQueryResultsUnmanageable, isResultIncomplete} = props.isCantDisplayGraphProps;

    if (!props.graphProps.queryGraphRequest.data || !props.graphProps.structuredGraph) {
        return null;
    }

    if (isQueryResultsUnmanageable) {
        return (
            <GraphDisplayErr label="The graph is too large to be displayed. Try again with a more concrete query." />
        );
    }

    if (isResultIncomplete) {
        if (props.resultsListProps.queryResultsListItems?.length) {
            return (
                <GraphDisplayErr label="The graph is not displayed because it has no edges. You can view results as a list in right panel." />
            );
        }

        return (
            <GraphDisplayErr label="This query has no results. The graph cannot be displayed." />
        );
    }

    const selectedMaxPathLengthOption = props.maxPathLengthOptions[
        props.graphProps.graphSettings.selectedViewKind
    ].find((option) => option.pathLength === props.graphProps.graphSettings.maxPathLength)!;
    const isCurrentGraphUnmanageable = isGraphUnmanageable(
        selectedMaxPathLengthOption.amountOfNodes,
        props.graphProps.graphSettings.graphType
    );

    if (isCurrentGraphUnmanageable) {
        return (
            <GraphDisplayErr label="The graph is too large to be displayed. Select the lower value in the Max Path Length field." />
        );
    }

    if (props.graphProps.graphSettings.graphType === GraphType.cytoscape) {
        if (props.graphProps.layoutAndCameraProps.isBrowserUnsupported) {
            return <BrowserNotSupportedView />;
        }

        const isAllNodesInvisible =
            !props.graphProps.hiddenNodesProps.isHiddenNodesVisible &&
            props.graphProps.queryGraphRequest.data.nodes.every((node) =>
                props.graphProps.hiddenNodesProps.hiddenNodes.includes(node.id)
            );
        if (!props.graphProps.layoutAndCameraProps.visibleNodesPositions && !isAllNodesInvisible) {
            return <GraphLoadingComponent />;
        }

        return (
            <CytoscapeGraphView
                nodes={props.graphProps.queryGraphRequest.data.nodes}
                edges={props.graphProps.queryGraphRequest.data.edges}
                selectNodes={props.selectionProps.onNodesSelected}
                selectedNodes={props.selectionProps.selectedNodes}
                hoveredNodes={props.selectionProps.hoveredNodes}
                setHoveredNodes={props.selectionProps.setHoveredNodes}
                nodePositions={{
                    ...props.graphProps.layoutAndCameraProps.visibleNodesPositions,
                    ...props.graphProps.layoutAndCameraProps.invisbleNodesPositions
                }}
                handleNodeMoved={props.graphProps.layoutAndCameraProps.handleNodeMoved}
                zoom={props.graphProps.layoutAndCameraProps.zoom}
                setZoom={props.graphProps.layoutAndCameraProps.setZoom}
                pan={props.graphProps.layoutAndCameraProps.pan}
                setPan={props.graphProps.layoutAndCameraProps.setPan}
                key={`${props.graphProps.layoutAndCameraProps.layoutAlgorithm}${props.graphProps.graphSettings.graphType}${props.graphProps.graphSettings.maxPathLength}`}
                isPresentationEdited={props.graphProps.presentationEditedProps.isPresentationEdited}
                hideNodesProps={props.graphProps.hideNodesProps}
                hiddenNodes={props.graphProps.hiddenNodesProps.hiddenNodes}
            />
        );
    }

    if (!props.graphProps.genestackGraphGrid) {
        return null;
    }

    return (
        <GenestackGraphView
            genestackGraphGrid={props.graphProps.genestackGraphGrid}
            selectNodes={props.selectionProps.onNodesSelected}
            selectedNodes={props.selectionProps.selectedNodes}
            hoveredNodes={props.selectionProps.hoveredNodes}
            hoveredEdges={props.selectionProps.hoveredEdges}
            setHoveredNodes={props.selectionProps.setHoveredNodes}
            setHoveredEdges={props.selectionProps.setHoveredEdges}
        />
    );
}
