import * as React from 'react';
import {Core} from 'cytoscape';
import popper from '@popperjs/core';
import {
    getDefaultEdgeStyles,
    getUnselectedNodeStyles,
    getHoveredNodeStyles,
    getSelectedNodeStyles,
    getUnhoveredNodeStyles,
    getSelectedEdgeStyles,
    getHiddenNodeStyles,
    getHiddenEdgeStyles,
    getUnhiddenNodeStyles,
    getUnhiddenEdgeStyles
} from './get-dynamic-styles';
import {createPopper} from './create-popper';
import {useGraphStateDiff} from './use-graph-state-diff';
import {getEdgeCytoscapeId} from './utils';
import {GraphEdge, GraphLayout, GraphNode} from '../../interface';
import {createEdgeElement, createNodeElement} from './create-elements';

interface Props {
    nodes: GraphNode[];
    edges: GraphEdge[];
    nodePositions: GraphLayout;
    graphStateDiffProps: ReturnType<typeof useGraphStateDiff>;
    csRef: React.MutableRefObject<Core | null>;
    containerElementRef: React.MutableRefObject<HTMLDivElement | null>;
}

export function useDynamicStyles(props: Props) {
    const {graphStateDiffProps, nodes, edges, nodePositions, csRef} = props;
    const {
        newSelectedNodes,
        newUnselectedNodes,
        newPartiallyHiddenNodes,
        newFullyHiddenNodes,
        newHoveredNodes,
        newUnhoveredNodes,
        newSelectedEdges,
        newPartiallyHiddenEdges,
        newFullyHiddenEdges,
        newUnselectedEdges,
        newPartiallyUnhiddenNodes,
        newFullyUnhiddenNodes,
        newPartiallyUnhiddenEdges,
        newFullyUnhiddenEdges,
        getIsNodeFullyHidden,
        getIsNodePartiallyHidden,
        getIsEdgePartiallyHidden,
        getIsEdgeFullyHidden
    } = graphStateDiffProps;
    const nodePositionsRef = React.useRef(nodePositions);
    nodePositionsRef.current = nodePositions;
    const popperMapRef = React.useRef<{[id: number]: popper.Instance}>({});

    React.useEffect(() => {
        csRef.current?.batch(() => {
            newSelectedNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getSelectedNodeStyles());
            });

            newUnselectedNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getUnselectedNodeStyles());
            });

            newPartiallyHiddenNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getHiddenNodeStyles());
            });

            newPartiallyUnhiddenNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getUnhiddenNodeStyles());
            });

            newFullyHiddenNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).remove();
            });

            if (newFullyUnhiddenNodes) {
                const nodesToAdd = nodes.filter(
                    ({id}) =>
                        id === newFullyUnhiddenNodes.find((unhiddenNodeId) => unhiddenNodeId === id)
                )!;

                csRef.current?.add(
                    nodesToAdd.map((node) =>
                        createNodeElement(node, nodePositionsRef.current, getIsNodePartiallyHidden)
                    )
                );

                const edgesToAdd = edges.filter(
                    ({startViewNodeId, endViewNodeId}) =>
                        (newFullyUnhiddenNodes.includes(startViewNodeId) &&
                            !getIsNodeFullyHidden(endViewNodeId)) ||
                        (newFullyUnhiddenNodes.includes(endViewNodeId) &&
                            !getIsNodeFullyHidden(startViewNodeId))
                );

                csRef.current?.add(
                    edgesToAdd.map((edge) => createEdgeElement(edge, getIsEdgePartiallyHidden))
                );
            }

            newHoveredNodes.forEach((nodeId) => {
                const el = csRef.current?.getElementById(String(nodeId));
                if (!el || !el.length) {
                    return;
                }

                el.style(getHoveredNodeStyles());

                const popperInstance = createPopper(el, props.containerElementRef);

                if (popperInstance) {
                    popperMapRef.current[nodeId] = popperInstance;
                }
            });

            newUnhoveredNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getUnhoveredNodeStyles());

                if (popperMapRef.current[nodeId]) {
                    try {
                        // for some reason it crashes in this line sometimes, so I wrapped
                        // it into try...catch to suppress it
                        props.containerElementRef.current?.removeChild(
                            popperMapRef.current[nodeId].state.elements.popper
                        );
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                    popperMapRef.current[nodeId].destroy();
                }
            });

            newSelectedEdges.forEach((id) => {
                csRef.current
                    ?.getElementById(getEdgeCytoscapeId(id))
                    .style(getSelectedEdgeStyles());
            });

            newPartiallyHiddenEdges.forEach((id) => {
                csRef.current?.getElementById(getEdgeCytoscapeId(id)).style(getHiddenEdgeStyles());
            });

            newFullyHiddenEdges.forEach((edgeId) => {
                csRef.current?.getElementById(String(edgeId)).remove();
            });

            newPartiallyUnhiddenEdges.forEach((id) => {
                csRef.current
                    ?.getElementById(getEdgeCytoscapeId(id))
                    .style(getUnhiddenEdgeStyles());
            });

            newUnselectedEdges.forEach((id) => {
                csRef.current
                    ?.getElementById(getEdgeCytoscapeId(id))
                    .style(getDefaultEdgeStyles(getIsEdgePartiallyHidden(id)));
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        newSelectedNodes,
        newHoveredNodes,
        newUnhoveredNodes,
        newUnselectedNodes,
        newSelectedEdges,
        newUnselectedEdges,
        newPartiallyHiddenNodes,
        newFullyHiddenNodes,
        newPartiallyUnhiddenNodes,
        newFullyUnhiddenNodes,
        newPartiallyHiddenEdges,
        newFullyHiddenEdges,
        newPartiallyUnhiddenEdges,
        newFullyUnhiddenEdges,
        getIsEdgeFullyHidden,
        getIsEdgePartiallyHidden,
        nodes,
        edges
    ]);
}
