import {PageContent, Preloader, Tab, Tabs} from '@genestack/ui';
import React from 'react';
import {GraphViewKind} from '../../../../../../components/graph/interface';
import {RightPanelHeader} from '../right-panel-header';
import {ContinuantsStatistics} from './continuants-statistics';
import {ProcessesStatistics} from './processes-statistics';
import {useFoldingItemsState} from './use-folding-items-state';
import {useGetQueryStats} from './use-get-query-stats';
import {showErrorNotification} from '../../../../../../components/notifications-center/notifications-store';

interface Props {
    queryId: number;
    viewKind: GraphViewKind;
    maxPathLength: number;
    selectedGraphNodes: number[];
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
    setHoveredNodes: (nodeIds: number[]) => void;
    closePanel: () => void;
}

enum StatisticsTabs {
    CONTINUANTS = 'CONTINUANTS',
    PROCESSES = 'PROCESSES'
}

const StatisticsComponent = (props: Props) => {
    const [selectedTab, setSelectedTab] = React.useState(StatisticsTabs.CONTINUANTS);
    const queryStats = useGetQueryStats(
        props.queryId,
        props.viewKind,
        props.maxPathLength,
        showErrorNotification('Request error, try again later')
    );
    const continuantsFoldingStateProps = useFoldingItemsState();
    const processesFoldingStateProps = useFoldingItemsState();

    return (
        <React.Fragment>
            <RightPanelHeader closePanel={props.closePanel}>
                <Tabs value={selectedTab} onValueChange={setSelectedTab}>
                    <Tab value={StatisticsTabs.CONTINUANTS}>Continuants</Tab>
                    <Tab value={StatisticsTabs.PROCESSES}>Processes</Tab>
                </Tabs>
            </RightPanelHeader>

            {(function () {
                if (!queryStats.data) {
                    return (
                        <PageContent>
                            <Preloader show count={5} box="paragraph" />
                        </PageContent>
                    );
                }

                if (selectedTab === StatisticsTabs.CONTINUANTS) {
                    return (
                        <ContinuantsStatistics
                            selectedGraphNodes={props.selectedGraphNodes}
                            selectGraphNodes={props.selectGraphNodes}
                            setHoveredNodes={props.setHoveredNodes}
                            foldingStateProps={continuantsFoldingStateProps}
                            groupedContinuantsMap={queryStats.data.groupedContinuantsMap}
                        />
                    );
                }

                return (
                    <ProcessesStatistics
                        processes={queryStats.data.processes}
                        foldingStateProps={processesFoldingStateProps}
                        selectedGraphNodes={props.selectedGraphNodes}
                        selectGraphNodes={props.selectGraphNodes}
                        setHoveredNodes={props.setHoveredNodes}
                    />
                );
            })()}
        </React.Fragment>
    );
};

export const Statistics = React.memo(StatisticsComponent);
