import React from 'react';
import {StructuredGraph} from './structured-graph';
import {getDeadEndNodes} from './get-dead-end-nodes';
import {deselectFullyHiddenNodes} from '../../../../application/query-workflow/query-layout/completed-query/results-graph/use-hidden-nodes';
import {GraphLayout} from '../../interface';

interface Props {
    hiddenNodes: number[];
    selectedNodes: number[];
    setSelectedNodes: (nodes: number[]) => void;
    setHiddenNodes: (nodeIds: number[]) => void;
    structuredGraph?: StructuredGraph;
    isHiddenNodesVisible: boolean;
    toggleIsHiddenNodesVisible: () => void;
    visibleNodesPositions?: GraphLayout;
    setVisibleNodesPositions: (val: string) => void;
    invisibleNodesPositions?: GraphLayout;
    setInvisibleNodesPositions: (val: string) => void;
}

export function useHideNodes(props: Props) {
    const {
        hiddenNodes,
        setHiddenNodes,
        selectedNodes,
        structuredGraph,
        setSelectedNodes,
        isHiddenNodesVisible,
        visibleNodesPositions,
        invisibleNodesPositions,
        setVisibleNodesPositions,
        setInvisibleNodesPositions,
        toggleIsHiddenNodesVisible
    } = props;

    // visible and invisible nodes positions are stored separately in two different structures,
    // so when node(s) become visible or invisible, we should move the coordinates from one
    // structure to the other
    const moveNodePosBtwVisibleInvisible = React.useCallback(
        (newVisibleNodes: number[], newInvisibleNodes: number[]) => {
            const newInvisibleNodesSet = new Set(newInvisibleNodes);
            const newVisibleNodesSet = new Set(newVisibleNodes);
            const newVisibleNodesPositions: GraphLayout = {};
            const newInvisibleNodesPositions: GraphLayout = {};
            if (visibleNodesPositions) {
                Object.keys(visibleNodesPositions).forEach((nodeId) => {
                    if (newInvisibleNodesSet.has(Number(nodeId))) {
                        newInvisibleNodesPositions[nodeId] = visibleNodesPositions[nodeId];
                    } else {
                        newVisibleNodesPositions[nodeId] = visibleNodesPositions[nodeId];
                    }
                });
            }

            if (invisibleNodesPositions) {
                Object.keys(invisibleNodesPositions).forEach((nodeId) => {
                    if (newVisibleNodesSet.has(Number(nodeId))) {
                        newVisibleNodesPositions[nodeId] = invisibleNodesPositions[nodeId];
                    } else {
                        newInvisibleNodesPositions[nodeId] = invisibleNodesPositions[nodeId];
                    }
                });
            }

            setVisibleNodesPositions(JSON.stringify(newVisibleNodesPositions));
            setInvisibleNodesPositions(JSON.stringify(newInvisibleNodesPositions));
        },
        [
            visibleNodesPositions,
            invisibleNodesPositions,
            setVisibleNodesPositions,
            setInvisibleNodesPositions
        ]
    );

    const setHiddenNodesAndMovePositions = React.useCallback(
        (newHiddenNodes: number[]) => {
            setHiddenNodes(newHiddenNodes);
            if (!isHiddenNodesVisible) {
                const hiddenNodesSet = new Set(hiddenNodes);
                const newHiddenNodesSet = new Set(newHiddenNodes);
                const newInvisibleNodes = newHiddenNodes.filter(
                    (nodeId) => !hiddenNodesSet.has(nodeId)
                );
                const newVisibleNodes = hiddenNodes.filter(
                    (nodeId) => !newHiddenNodesSet.has(nodeId)
                );
                moveNodePosBtwVisibleInvisible(newVisibleNodes, newInvisibleNodes);
            }
        },
        [setHiddenNodes, hiddenNodes, isHiddenNodesVisible, moveNodePosBtwVisibleInvisible]
    );

    const hasHiddenSelectedNodes = React.useMemo(() => {
        const hiddenNodesSet = new Set([...hiddenNodes]);
        return selectedNodes.some((nodeId) => hiddenNodesSet.has(nodeId));
    }, [selectedNodes, hiddenNodes]);

    const isAllSelectedNodesHidden = React.useMemo(() => {
        return selectedNodes.every((nodeId) => hiddenNodes.includes(nodeId));
    }, [selectedNodes, hiddenNodes]);

    const isCanHideSelectedNodes = !!props.selectedNodes.length && !isAllSelectedNodesHidden;

    const hideNodesAndDeadEnds = React.useCallback(() => {
        const newHiddenNodes = new Set<number>([...hiddenNodes, ...selectedNodes]);
        selectedNodes.forEach((selectedNodeId) =>
            getDeadEndNodes(structuredGraph!, selectedNodeId).forEach((id) =>
                newHiddenNodes.add(id)
            )
        );

        setHiddenNodesAndMovePositions(Array.from(newHiddenNodes));
        if (!isHiddenNodesVisible) {
            deselectFullyHiddenNodes(Array.from(newHiddenNodes), selectedNodes, setSelectedNodes);
        }
    }, [
        selectedNodes,
        hiddenNodes,
        setHiddenNodesAndMovePositions,
        structuredGraph,
        setSelectedNodes,
        isHiddenNodesVisible
    ]);

    const hideSelectedNodes = React.useCallback(() => {
        const newVal = Array.from(new Set<number>([...selectedNodes, ...hiddenNodes]));
        setHiddenNodesAndMovePositions(newVal);
        if (!isHiddenNodesVisible) {
            deselectFullyHiddenNodes(Array.from(newVal), selectedNodes, setSelectedNodes);
        }
    }, [
        setHiddenNodesAndMovePositions,
        selectedNodes,
        setSelectedNodes,
        hiddenNodes,
        isHiddenNodesVisible
    ]);

    const hideAllNodes = React.useCallback(() => {
        setHiddenNodesAndMovePositions(structuredGraph!.allNodes.map((node) => node.id));
    }, [setHiddenNodesAndMovePositions, structuredGraph]);

    const unhideAllNodes = React.useCallback(() => {
        setHiddenNodesAndMovePositions([]);
    }, [setHiddenNodesAndMovePositions]);

    const unhideSelectedNodes = React.useCallback(() => {
        const selectedNodesSet = new Set([...selectedNodes]);

        setHiddenNodesAndMovePositions(
            hiddenNodes.filter((nodeId) => !selectedNodesSet.has(nodeId))
        );
    }, [hiddenNodes, setHiddenNodesAndMovePositions, selectedNodes]);

    return {
        hideNodesAndDeadEnds,
        hideSelectedNodes,
        hideAllNodes,
        unhideAllNodes,
        isCanHideSelectedNodes,
        unhideSelectedNodes,
        hasHiddenNodes: hiddenNodes.length > 0,
        isAllNodesHidden: hiddenNodes.length === structuredGraph?.allNodes.length,
        hasHiddenSelectedNodes,
        isHiddenNodesVisible,
        toggleIsHiddenNodesVisible: React.useCallback(() => {
            const isHiddenNodesVisibleNew = !isHiddenNodesVisible;
            if (!isHiddenNodesVisibleNew) {
                moveNodePosBtwVisibleInvisible([], hiddenNodes);
            } else {
                moveNodePosBtwVisibleInvisible(hiddenNodes, []);
            }
            toggleIsHiddenNodesVisible();
        }, [
            toggleIsHiddenNodesVisible,
            moveNodePosBtwVisibleInvisible,
            hiddenNodes,
            isHiddenNodesVisible
        ])
    };
}
