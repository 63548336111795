import {useQuery} from 'react-query';

import {GraphResponse, GraphViewKind} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';

function getQueryResultsSubGraphRequest(
    queryId: number,
    maxPathLength: number,
    viewKind: GraphViewKind
) {
    return apiClient.get<GraphResponse>({
        path: `queries-service/api/queries/${queryId}/connection-graph/${viewKind}`,
        query: {
            'path-length-limit': maxPathLength
        }
    });
}

export function useRequestQueryResultsSubgraph(
    viewKind: GraphViewKind,
    queryId: number,
    maxPathLength: number
) {
    return useQuery({
        queryKey: ['query-results-graph', queryId, maxPathLength, viewKind],
        queryFn: () => getQueryResultsSubGraphRequest(queryId, maxPathLength, viewKind)
    });
}
