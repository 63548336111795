import {useQuery} from 'react-query';
import {GraphViewKind} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';
import {StartEndNodesResponse} from '../interface';

function getStartEndNodesRequest(queryId: number, viewKind: GraphViewKind, maxPathLength: number) {
    return apiClient.get<StartEndNodesResponse>({
        path: `queries-service/api/queries/${queryId}/terminal-nodes/${viewKind}`,
        query: {
            'path-length-limit': maxPathLength
        }
    });
}

export function useGetStartEndNodes(
    queryId: number,
    viewKind: GraphViewKind,
    maxPathLength: number,
    onError: (error: unknown) => void
) {
    return useQuery({
        queryKey: ['query-graph-start-end-nodes', queryId, viewKind, maxPathLength],
        queryFn: () => getStartEndNodesRequest(queryId, viewKind, maxPathLength),
        onError
    });
}
