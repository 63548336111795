import {useQuery} from 'react-query';
import {GraphStatistics, GroupedContinuantsMap, UnparsedGraphStatistics} from '../interface';
import {numericSortComparator} from '../../../../../../utils/array';
import {GraphViewKind} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';

function parseStats(stats: UnparsedGraphStatistics): GraphStatistics {
    // group continuants by type
    const groupedContinuantsMap = stats.continuants.reduce<GroupedContinuantsMap>(
        (continuantsMap, continuant) => {
            if (!continuantsMap[continuant.type]) {
                return {...continuantsMap, [continuant.type]: [continuant]};
            }

            return {
                ...continuantsMap,
                [continuant.type]: [...continuantsMap[continuant.type], continuant]
            };
        },
        {}
    );

    // sort continuants inside group
    Object.keys(groupedContinuantsMap).forEach((continuantsGroup) => {
        groupedContinuantsMap[continuantsGroup] = groupedContinuantsMap[continuantsGroup].sort(
            numericSortComparator('occurrencesCount')
        );
    });

    // sort groups
    const sortedContinuantsMap: GroupedContinuantsMap = {};
    Object.keys(groupedContinuantsMap)
        .sort(
            (group1, group2) =>
                groupedContinuantsMap[group2].length - groupedContinuantsMap[group1].length
        )
        .forEach((group) => {
            sortedContinuantsMap[group] = groupedContinuantsMap[group];
        });

    return {
        processes: stats.processes,
        groupedContinuantsMap: sortedContinuantsMap
    };
}

function getQueryStatsRequest(queryId: number, viewKind: GraphViewKind, maxPathLength: number) {
    return apiClient
        .get<UnparsedGraphStatistics>({
            path: `queries-service/api/queries/${queryId}/connection-graph/${viewKind}/statistics`,
            query: {
                'path-length-limit': maxPathLength
            }
        })
        .then(parseStats);
}

export function useGetQueryStats(
    queryId: number,
    viewKind: GraphViewKind,
    maxPathLength: number,
    onError: (error: unknown) => void
) {
    return useQuery({
        queryKey: ['query-graph-statistics', queryId, viewKind, maxPathLength],
        queryFn: () => getQueryStatsRequest(queryId, viewKind, maxPathLength),
        onError
    });
}
