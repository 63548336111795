import {QueryNodeType, StructuredGraphNode} from '../../interface';
import {StructuredGraph} from './structured-graph';

// get a function that works in one of two directions and can determine
// if path to start or end nodes (depending on direction) from the argument node
// lies only through the removed node
function getFuncIsConnectedOnlyThroughRemoved(
    removedNodeId: number,
    searchConnectionTo: QueryNodeType.START | QueryNodeType.END,
    structuredGraph: StructuredGraph
) {
    const cacheMap: {[key: number]: boolean} = {};
    const weightMap =
        searchConnectionTo === QueryNodeType.START
            ? structuredGraph.weightFromStartMap
            : structuredGraph.weightFromEndMap;

    return function isConnectedOnlyThroughRemoved(node: StructuredGraphNode): boolean {
        const processedStack: StructuredGraphNode[] = [node];

        while (processedStack.length) {
            const processedNode = processedStack[processedStack.length - 1]!;

            const nextLvlNodes =
                searchConnectionTo === QueryNodeType.START
                    ? processedNode.incomingEdges.map(({sourceNode}) => sourceNode)
                    : processedNode.outgoingEdges.map(({targetNode}) => targetNode);

            const processedQueueSet = new Set([...processedStack]);

            if (
                nextLvlNodes.every(
                    (n) => cacheMap[n.id] || n.id === removedNodeId || processedQueueSet.has(n)
                )
            ) {
                cacheMap[processedNode.id] = true;
                processedStack.pop();
                continue;
            }

            if (
                nextLvlNodes.some(
                    ({nodeType, id}) => nodeType === searchConnectionTo || cacheMap[id] === false
                )
            ) {
                cacheMap[processedNode.id] = false;
                processedStack.pop();
                continue;
            }

            const unprocessedNextLvlNodes = nextLvlNodes.filter(
                (n) =>
                    cacheMap[n.id] === undefined &&
                    n.nodeType !== searchConnectionTo &&
                    n.id !== removedNodeId &&
                    !processedQueueSet.has(n)
            );
            let nextLvlNodeWithMinWeight = unprocessedNextLvlNodes[0];
            unprocessedNextLvlNodes.forEach((n) => {
                if (weightMap[n.id] < weightMap[nextLvlNodeWithMinWeight.id]) {
                    nextLvlNodeWithMinWeight = n;
                }
            });
            processedStack.push(nextLvlNodeWithMinWeight);
        }

        return cacheMap[node.id];
    };
}

// returns all nodes that become dead-end (not part of a path from a start node to an end node)
// once removedNode is removed
export function getDeadEndNodes(structuredGraph: StructuredGraph, removedNodeId: number) {
    const deadEndNodes = new Set<number>();
    const processedNodesQueue: StructuredGraphNode[] = [structuredGraph.getNode(removedNodeId)!];
    // this map is to prevent the func from entering infinite cycle when the graph has cycles
    let processedNodesMap: {[key: number]: true} = {};

    const checkIfOnlyDescentsFromRemoved = getFuncIsConnectedOnlyThroughRemoved(
        removedNodeId,
        QueryNodeType.START,
        structuredGraph
    );
    const checkIfOnlyCanLeadToRemoved = getFuncIsConnectedOnlyThroughRemoved(
        removedNodeId,
        QueryNodeType.END,
        structuredGraph
    );

    // traverse the graph forward to find all nodes that descend only from the removed node...
    // meaning they have no other way to reach back to any start node except through the...
    // removed node
    do {
        const currentNode = processedNodesQueue.shift()!;
        processedNodesMap[currentNode.id] = true;

        if (currentNode.id === removedNodeId || checkIfOnlyDescentsFromRemoved(currentNode)) {
            const nextNodes = currentNode.outgoingEdges
                .map((edge) => edge.targetNode)
                .filter(
                    // eslint-disable-next-line no-loop-func
                    ({id, nodeType}) => !processedNodesMap[id] && nodeType !== QueryNodeType.START
                );
            processedNodesQueue.push(...nextNodes);
            deadEndNodes.add(currentNode.id);
        }
    } while (processedNodesQueue.length > 0);

    // get queue and map in an initial state
    processedNodesQueue.push(structuredGraph.getNode(removedNodeId)!);
    processedNodesMap = {};

    // traverse the graph backward to find all nodes that lead only to the removed node
    // meaning they have no other way to reach any end node except through the
    // removed node
    do {
        const currentNode = processedNodesQueue.shift()!;
        processedNodesMap[currentNode.id] = true;

        if (currentNode.id === removedNodeId || checkIfOnlyCanLeadToRemoved(currentNode)) {
            const prevNodes = currentNode.incomingEdges
                .map((edge) => edge.sourceNode)
                .filter(
                    ({id, nodeType}) => !processedNodesMap[id] && nodeType !== QueryNodeType.END
                );
            processedNodesQueue.push(...prevNodes);
            deadEndNodes.add(currentNode.id);
        }
    } while (processedNodesQueue.length > 0);

    return Array.from(deadEndNodes).filter((node) => node !== removedNodeId);
}
