import {useQuery} from 'react-query';
import {GraphResponse, GraphViewKind} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';
import {NeighborhoodGraphDirection} from '../interface';

interface Props {
    documentId: string;
    factIds: string[];
    globalVersionId: number;
    onError: (err: unknown) => void;
    selectedViewKind: GraphViewKind;
    maxDistance: number;
    direction: NeighborhoodGraphDirection;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function requestNeighborhoodGraph(
    documentId: string,
    factsId: string[],
    globalVersionId: number,
    selectedViewKind: GraphViewKind,
    maxDistance: number,
    direction: NeighborhoodGraphDirection
) {
    return apiClient
        .get<GraphResponse>({
            path: `/queries-service/api/documents/${documentId}/connection-graph/${selectedViewKind}`,
            query: {
                depth: maxDistance,
                direction,
                'global-version': globalVersionId,
                'fact-ids': factsId[0]
            }
        })
        .then((response) => ({
            nodes: response.nodes,
            edges: response.edges.map((edge) => ({
                ...edge,
                id: -edge.id
            }))
        }));
}

export function useRequestNeighborhoodGraph(props: Props) {
    return useQuery(
        [
            'request-neighborhood-graph',
            props.globalVersionId,
            props.documentId,
            ...props.factIds,
            props.selectedViewKind,
            props.maxDistance,
            props.direction
        ],
        () =>
            requestNeighborhoodGraph(
                props.documentId,
                props.factIds,
                props.globalVersionId,
                props.selectedViewKind,
                props.maxDistance,
                props.direction
            ),
        {
            onError: props.onError
        }
    );
}
