import {Position} from 'cytoscape';
import React from 'react';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {QueryGraphLayoutAlgorithm} from '../../../../../components/graph/cytoscape-graph/interface';
import {GraphViewKind} from '../../../../../components/graph/interface';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {GraphPresentation, GraphPresentationDetails} from './interface';

interface Props {
    queryId: number;
    maxPathLength: number;
    selectedViewKind: GraphViewKind;
    layoutAlgorithm: QueryGraphLayoutAlgorithm;
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
}

export const defaultPan = {x: 0, y: 0};
export const defaultZoom = '0';

export function useCameraProps(props: Props) {
    const [pan, setPan] = useLsValue<
        string,
        [number, number],
        [QueryGraphLayoutAlgorithm, string, GraphViewKind]
    >({
        rewriteKeyParams: [props.layoutAlgorithm, `${props.maxPathLength}`, props.selectedViewKind],
        changeEntityParams: [props.queryId, props.selectedPresentation.id],
        getKey: ([qId, pId], [algo, mPLength, view]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.${algo}.${mPLength}.${view}.pan`,
        defaultValue: JSON.stringify(defaultPan),
        validateValue: (val) => !Number.isNaN(JSON.parse(val).x) && !Number.isNaN(JSON.parse(val).y)
    });

    const [zoom, setZoom] = useLsValue<string, [number, number], []>({
        rewriteKeyParams: [],
        changeEntityParams: [props.queryId, props.selectedPresentation.id],
        getKey: ([qId, pId]) => `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.zoom`,
        defaultValue: defaultZoom,
        validateValue: (val) => !Number.isNaN(val) && Number(val) <= 1 && Number(val) >= 0
    });

    return {
        pan: JSON.parse(pan) as Position,
        setPan: React.useCallback((val: Position) => setPan(JSON.stringify(val)), [setPan]),
        zoom: Number(zoom),
        setZoom: React.useCallback((val: number) => setZoom(String(val)), [setZoom])
    };
}
