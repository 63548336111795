import React from 'react';
import {usePresentationEditedProps} from './use-presentation-edited-props';
import {useGraphSettings} from './use-graph-settings';
import {useLayoutAndCameraProps} from './use-layout-and-camera-props';
import {GraphPresentationDetails} from './interface';
import {useHiddenNodes} from './use-hidden-nodes';
import {getNodesFromRawNodes, parsePresDetailsLayout} from './helpers';
import {StructuredGraph} from '../../../../../components/graph/cytoscape-graph/graph-logic/structured-graph';

interface Props {
    presentationEditedProps: ReturnType<typeof usePresentationEditedProps>;
    graphSettings: ReturnType<typeof useGraphSettings>;
    layoutAndCameraProps: ReturnType<typeof useLayoutAndCameraProps>;
    hiddenNodesProps: ReturnType<typeof useHiddenNodes>;
    structuredGraph?: StructuredGraph;
}

export function useUnsavedChangesActions(props: Props) {
    const {
        presentationEditedProps: {clearIsPresentationEdited, handlePresentationEdited},
        hiddenNodesProps: {clearHiddenNodes, setHiddenRawNodeIds, isHiddenNodesVisible},
        structuredGraph,
        graphSettings: {
            clearGraphType,
            setGraphType,
            clearSelectedViewKind,
            setSelectedViewKind,
            clearMaxPathLength,
            setMaxPathLength
        },
        layoutAndCameraProps: {
            clearIsCustomLayout,
            setIsCustomLayout,
            clearLayoutAlgorithm,
            selectLayoutAlgorithm,
            clearNodePositions,
            setVisibleNodesPositions,
            setInvisibleNodesPositions
        }
    } = props;

    const clearUnsavedChanges = React.useCallback(() => {
        clearIsPresentationEdited();
        clearGraphType();
        clearSelectedViewKind();
        clearMaxPathLength();
        clearIsCustomLayout();
        clearLayoutAlgorithm();
        clearNodePositions();
        clearHiddenNodes();
    }, [
        clearIsPresentationEdited,
        clearGraphType,
        clearSelectedViewKind,
        clearMaxPathLength,
        clearIsCustomLayout,
        clearLayoutAlgorithm,
        clearNodePositions,
        clearHiddenNodes
    ]);

    const setUnsavedChanges = React.useCallback(
        (presentationDetails: GraphPresentationDetails) => {
            handlePresentationEdited();
            setGraphType(presentationDetails.graphType);
            setSelectedViewKind(presentationDetails.viewKind);
            setMaxPathLength(presentationDetails.maxPathLength);
            setIsCustomLayout(String(presentationDetails.isLayoutCustom) as 'true' | 'false');
            selectLayoutAlgorithm(presentationDetails.layoutAlgorithm);
            setHiddenRawNodeIds(presentationDetails.hiddenNodes);

            const hiddenNodeIds = getNodesFromRawNodes(
                presentationDetails.hiddenNodes,
                structuredGraph
            );
            const {visibleNodesPositions, invisibleNodesPositions} = parsePresDetailsLayout(
                presentationDetails.layout,
                isHiddenNodesVisible,
                (nodeId: number) => hiddenNodeIds.includes(nodeId)
            );
            setVisibleNodesPositions(visibleNodesPositions);
            setInvisibleNodesPositions(invisibleNodesPositions);
        },
        [
            handlePresentationEdited,
            setGraphType,
            setSelectedViewKind,
            setMaxPathLength,
            setIsCustomLayout,
            selectLayoutAlgorithm,
            setVisibleNodesPositions,
            setInvisibleNodesPositions,
            isHiddenNodesVisible,
            setHiddenRawNodeIds,
            structuredGraph
        ]
    );

    return {clearUnsavedChanges, setUnsavedChanges};
}
