import React from 'react';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import usePrevious from 'react-use/lib/usePrevious';
import {Button, chain, Controls, ControlsItem, Notification, Typography} from '@genestack/ui';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {
    GraphLayout,
    GridCoordinates,
    GraphNode,
    GraphViewKind,
    GraphEdge
} from '../../../../../components/graph/interface';
import {QueryGraphLayoutAlgorithm} from '../../../../../components/graph/cytoscape-graph/interface';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {defaultPan, defaultZoom, useCameraProps} from './use-camera-props';
import {showNotification} from '../../../../../components/notifications-center';
import {useCalculateLayout} from '../../../../../components/graph/cytoscape-graph/graph-logic/use-calculate-layout';
import {NotificationElement} from '../../../../../components/notifications-center/notifications-store';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {parsePresDetailsLayout, persistPositionsByRawNodeId} from './helpers';

interface Props {
    maxPathLength: number;
    isDefaultMPLength: boolean;
    selectedViewKind: GraphViewKind;
    isDefaultSelViewKind: boolean;
    queryId: number;
    nodes?: GraphNode[];
    edges?: GraphEdge[];
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
    handlePresentationEdited: () => void;
    isPresentationEdited: boolean;
    isHiddenNodesVisible: boolean;
    isHiddenNode: (nodeId: number) => boolean;
    isHiddenEdge: (edgeId: number) => boolean;
}

interface SavedLayoutNotificationProps {
    onClick?: () => void;
    onClose?: () => void;
    isDestroyed?: boolean;
}

function getSavedLayoutNotification(props: SavedLayoutNotificationProps) {
    return (
        <Notification
            countdown={props.isDestroyed ? 'active' : 'none'}
            countdownDuration={0}
            onClose={props.onClose}
        >
            <Typography intent="warning">The previous graph layout was left unchanged</Typography>
            <Controls justify="end">
                <ControlsItem>
                    <Button size="small" onClick={props.onClick}>
                        Rebuild layout
                    </Button>
                </ControlsItem>
            </Controls>
        </Notification>
    ) as NotificationElement;
}

// todo: separate layout and camera props
export function useLayoutAndCameraProps(props: Props) {
    const {
        queryId,
        maxPathLength,
        isDefaultMPLength,
        selectedViewKind,
        isDefaultSelViewKind,
        nodes,
        edges,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited,
        isPresentationEdited,
        isHiddenNodesVisible,
        isHiddenNode,
        isHiddenEdge
    } = props;

    const [isBrowserUnsupported, setIsBrowserUnsupported] = React.useState<boolean>();

    const [layoutAlgorithm, setLayoutAlgorithm, clearLayoutAlgorithm, isDefaultAlgo] = useLsValue<
        QueryGraphLayoutAlgorithm,
        [number, number],
        []
    >({
        changeEntityParams: [queryId, selectedPresentation.id],
        getKey: ([qId, pId]) => `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.layoutAlgorithm`,
        rewriteKeyParams: [],
        defaultValue: selectedPresentationDetails.layoutAlgorithm,
        validateValue: (value) =>
            Object.values(QueryGraphLayoutAlgorithm).includes(value as QueryGraphLayoutAlgorithm)
    });
    const [isCustomLayout, setIsCustomLayout, clearIsCustomLayout] = useLsValue<
        'true' | 'false',
        [number, number],
        [number, GraphViewKind, QueryGraphLayoutAlgorithm]
    >({
        changeEntityParams: [queryId, selectedPresentation.id],
        rewriteKeyParams: [maxPathLength, selectedViewKind, layoutAlgorithm],
        getKey: ([qId, pId], [mPLength, viewType, lAlgo]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.${mPLength}.${viewType}.${lAlgo}.isCustomLayout`,
        defaultValue: isPresentationEdited
            ? 'false'
            : (String(selectedPresentationDetails.isLayoutCustom) as 'true' | 'false'),
        validateValue: (value) => value === 'true' || value === 'false'
    });
    const {
        visibleNodesPositions: visibleNodesPositionsDefault,
        invisibleNodesPositions: invisibleNodesPositionsDefault
    } = parsePresDetailsLayout(
        selectedPresentationDetails.layout,
        isHiddenNodesVisible,
        isHiddenNode
    );
    const [visibleNodesPositions, setVisibleNodesPositions, clearVisibleNodesPositions] =
        useLsValue<string, [number, number], [number, GraphViewKind, QueryGraphLayoutAlgorithm]>({
            changeEntityParams: [queryId, selectedPresentation.id],
            rewriteKeyParams: [maxPathLength, selectedViewKind, layoutAlgorithm],
            getKey: ([qId, pId], [mPLength, viewType, lAlgo]) =>
                `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.${mPLength}.${viewType}.${lAlgo}.layout`,
            defaultValue: (function () {
                /* these are the settings that influence layout.
                 * if some other settings were changed (eg hidden nodes) we should still use
                 * default value from graph presentation details */
                if (!isDefaultSelViewKind || !isDefaultAlgo || !isDefaultMPLength) {
                    return '';
                }

                return visibleNodesPositionsDefault;
            })(),
            validateValue: (value) =>
                !value ||
                Object.values(JSON.parse(value) as GraphLayout).every(
                    (coordinates: GridCoordinates) =>
                        !Number.isNaN(coordinates.x) && !Number.isNaN(coordinates.y)
                )
        });

    const [invisibleNodesPositions, setInvisibleNodesPositions, clearInvisibleNodesPositions] =
        useLsValue<string, [number, number], []>({
            changeEntityParams: [queryId, selectedPresentation.id],
            rewriteKeyParams: [],
            getKey: ([qId, pId]) =>
                `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.invisibleNodesPositions`,
            defaultValue: (function () {
                return invisibleNodesPositionsDefault;
            })(),
            validateValue: (value) =>
                !value ||
                Object.values(JSON.parse(value) as GraphLayout).every(
                    (coordinates: GridCoordinates) =>
                        !Number.isNaN(coordinates.x) && !Number.isNaN(coordinates.y)
                )
        });

    const {setZoom, setPan, ...restCameraProps} = useCameraProps({
        queryId,
        layoutAlgorithm,
        maxPathLength,
        selectedViewKind,
        selectedPresentation,
        selectedPresentationDetails
    });

    const calculateLayout = useCalculateLayout({
        nodes,
        edges,
        setNodePositions: setVisibleNodesPositions,
        setIsBrowserUnsupported,
        layoutAlgorithm,
        nodePositions: visibleNodesPositions,
        isHiddenNodesVisible,
        isHiddenEdge,
        isHiddenNode
    });

    const previousLoadedNodes = usePreviousDistinct(nodes, (prev, next) => !next);
    const prevVisibleNodesPositions = usePreviousDistinct(
        visibleNodesPositions,
        (prev, next) => !next
    );
    const prevInvisibleNodesPositions = usePreviousDistinct(
        invisibleNodesPositions,
        (prev, next) => !next
    );

    const previousZoom = usePrevious(restCameraProps.zoom);
    const previousPan = usePreviousDistinct(
        restCameraProps.pan,
        (prev, next) => JSON.stringify(next) === JSON.stringify(defaultPan)
    );

    const visibleNodePositionsRef = React.useRef(visibleNodesPositions);
    visibleNodePositionsRef.current = visibleNodesPositions;
    const prevVisibleNodePositionsRef = React.useRef(prevVisibleNodesPositions);
    prevVisibleNodePositionsRef.current = prevVisibleNodesPositions;
    const prevInvisibleNodePositionsRef = React.useRef(prevInvisibleNodesPositions);
    prevInvisibleNodePositionsRef.current = prevInvisibleNodesPositions;
    const previousLoadedNodesRef = React.useRef(previousLoadedNodes);
    previousLoadedNodesRef.current = previousLoadedNodes;
    const previousZoomRef = React.useRef(previousZoom);
    previousZoomRef.current = previousZoom;
    const previousPanRef = React.useRef(previousPan);
    previousPanRef.current = previousPan;
    // hack for manually closing the notification, see task JBKB-3237
    const isShowingNotificationRef = React.useRef(false);

    const onNodesLoaded = React.useCallback(
        (newNodes: GraphNode[], prevNodes?: GraphNode[]) => {
            if (visibleNodePositionsRef.current) {
                return;
            }

            if (prevNodes && prevInvisibleNodePositionsRef.current) {
                const newInvisibleNodesPositions = persistPositionsByRawNodeId(
                    prevNodes,
                    newNodes,
                    prevInvisibleNodePositionsRef.current
                );
                setInvisibleNodesPositions(JSON.stringify(newInvisibleNodesPositions));
            }

            if (
                prevVisibleNodePositionsRef.current &&
                prevNodes &&
                prevNodes.length > newNodes.length
            ) {
                // if new nodes arr has fewer nodes then previous nodes arr,
                // then try to save the layout using rawNodeIds...
                const newPositions = persistPositionsByRawNodeId(
                    prevNodes,
                    newNodes,
                    prevVisibleNodePositionsRef.current
                );

                setVisibleNodesPositions(JSON.stringify(newPositions));
                setPan(previousPanRef.current || {x: 0, y: 0});
                setZoom(previousZoomRef.current || 0);

                let closeNotificationFun: () => void;
                const notification = getSavedLayoutNotification({
                    onClick: () => {
                        setVisibleNodesPositions('');
                        calculateLayout();
                        closeNotificationFun!();
                        setPan(defaultPan);
                        setZoom(Number(defaultZoom));
                    },
                    onClose: () => {
                        isShowingNotificationRef.current = false;
                    }
                });

                const replaceNotification = showNotification(notification);
                isShowingNotificationRef.current = true;
                closeNotificationFun = () => {
                    if (isShowingNotificationRef.current) {
                        isShowingNotificationRef.current = false;
                        replaceNotification(getSavedLayoutNotification({isDestroyed: true}));
                    }
                };

                return closeNotificationFun;
            }

            // ...else just recalculate layout
            calculateLayout();
        },
        [setVisibleNodesPositions, setInvisibleNodesPositions, setZoom, setPan, calculateLayout]
    );

    const handleNodeMoved = React.useCallback(
        (newLayout: GraphLayout) => {
            setIsCustomLayout('true');
            setVisibleNodesPositions(JSON.stringify(newLayout));
            handlePresentationEdited();
        },
        [setIsCustomLayout, setVisibleNodesPositions, handlePresentationEdited]
    );

    return {
        layoutAlgorithm: layoutAlgorithm as QueryGraphLayoutAlgorithm,
        clearLayoutAlgorithm,
        visibleNodesPositions: visibleNodesPositions
            ? (JSON.parse(visibleNodesPositions) as GraphLayout)
            : undefined,
        invisbleNodesPositions: invisibleNodesPositions
            ? (JSON.parse(invisibleNodesPositions) as GraphLayout)
            : undefined,
        clearNodePositions: chain(clearVisibleNodesPositions, clearInvisibleNodesPositions)!,
        handleNodeMoved,
        isCustomLayout: isCustomLayout === 'true',
        clearIsCustomLayout,
        selectLayoutAlgorithm: chain(
            setLayoutAlgorithm,
            handlePresentationEdited,
            calculateLayout
        )!,
        isBrowserUnsupported,
        setZoom,
        setPan,
        setVisibleNodesPositions,
        setInvisibleNodesPositions,
        setIsCustomLayout,
        onNodesLoaded,
        ...restCameraProps
    };
}
