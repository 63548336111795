import {ResolvedNode} from '../../../webeditor/biokb-editor-adapters';

export enum QueryNodeType {
    START = 'START',
    INTERMEDIATE = 'INNER',
    END = 'END'
}

export enum FactNeighborhoodNodeType {
    INITIAL = 'INITIAL',
    OTHER = 'OTHER'
}

export enum GraphType {
    cytoscape = 'cytoscape',
    biokb = 'biokb'
}

export interface GraphNode {
    id: number;
    nodeType: QueryNodeType | FactNeighborhoodNodeType;
    label: string;
    /* The graph that is sent to FE (called view graph on BE)
    is constructed from a raw graph (simply called graph on BE).
    For each view type a separate view graph is constructed, and
    for merged view type several raw nodes can be merged onto one view node.
    Different view graphs consist of different view nodes with different ids
    and to match them between each other (eg to save selection when switching view type),
    we can use original (raw) node ids. */
    rawNodeIds: number[];
}

export enum RelationPresentation {
    ASSERTED = 'ASSERTED',
    REACTION = 'REACTION',
    MATCHED = 'MATCHED'
}

export interface GraphEdge {
    id: number;
    startViewNodeId: number;
    endViewNodeId: number;
    presentation: RelationPresentation;
}

export interface GraphResponse {
    nodes: GraphNode[];
    edges: GraphEdge[];
}

export interface StructuredGraphNode {
    id: number;
    nodeType: QueryNodeType;
    // eslint-disable-next-line no-use-before-define
    outgoingEdges: StructuredGraphEdge[];
    // eslint-disable-next-line no-use-before-define
    incomingEdges: StructuredGraphEdge[];
    rawNodeIds: number[];
}

export interface StructuredGraphEdge {
    id: number;
    sourceNode: StructuredGraphNode;
    targetNode: StructuredGraphNode;
    presentation: RelationPresentation;
}

export interface GridCoordinates {
    x: number;
    y: number;
}

export interface ResultMaxLengthOption {
    pathLength: number;
    amountOfNodes: number;
    amountOfEdges: number;
    minimalValuable: boolean;
}

export enum GraphViewKind {
    IDENTICAL = 'identical',
    MERGED = 'merged'
}

// todo: change to [key: number]
export type GraphLayout = {[key: string]: GridCoordinates};

export interface GraphNodeContent extends PathEntryDto {
    edgeIds: number[];
    collapsedEditorContent: ResolvedNode;
}

interface GraphFactPanelEntry {
    graphNodeId: number;
    summary: string;
}

export interface SimpleFactPanelEntry extends GraphFactPanelEntry {
    content: GraphNodeContent;
}

export interface MergedFactPanelEntry extends GraphFactPanelEntry {
    inputContents: GraphNodeContent[];
    outputContents: GraphNodeContent[];
}
